import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiVolume2, FiVolumeX } from 'react-icons/fi';
// import Countdown from 'react-countdown';
// import { Button, Snackbar } from '@material-ui/core';
// import Alert from '@material-ui/lab/Alert';

import * as anchor from '@project-serum/anchor';

// import { LAMPORTS_PER_SOL } from '@solana/web3.js';

import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';

import {
  // CandyMachine,
  // Something
  // awaitTransactionSignatureConfirmation,
  // getCandyMachineState,
  // mintOneToken,
  shortenAddress,
} from './candy-machine';

const ConnectButton = styled(WalletDialogButton)`
  background: #000 !important;
  border-radius: 0px !important;
  border: 2px solid #fff !important;
  &:hover {
    background: #323232 !important;
  }
`;

const MuteToggle = styled.button`
  padding: none;
  outline: none;
  background: transparent;
  border: none;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

const TextContainer = styled.div`
  padding: 100px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding-top: 0px;
    margin-top: -120px;
  }
`;

const ScreamsText = styled.h1`
  font-weight: 200;
  font-size: 44px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const ScreamsSubText = styled.h2`
  font-size: 16px;
  color: lightgrey;
  margin-top: 3px;
  font-weight: 400;
`;

const WalletAddyDisplay = styled.div`
  background: #000 !important;
  border-radius: 0px !important;
  border: 2px solid #fff !important;
  &:hover {
    background: #323232 !important;
  }
  padding: 6px 16px;
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 70px;
  color: #fff;
  cursor: pointer;
`;

const FixedButtonsWrapper = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ScreamVid = styled.video`
  max-width: 100vw;
  width: 550px;
  height: 550px;
`;

// const CounterText = styled.span``;
const HomeContainer = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
// const MintButton = styled(Button)``;

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [isMuted, setIsMuted] = useState(true);
  // const [balance, setBalance] = useState<number>();
  // const [isActive, setIsActive] = useState(false); // true when countdown completes
  // const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  // const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  // const [alertState, setAlertState] = useState<AlertState>({
  //   open: false,
  //   message: '',
  //   severity: undefined,
  // });

  // const [startDate, setStartDate] = useState(new Date(props.startDate));

  const handleToggleMuted = () => {
    setIsMuted(!isMuted);
  };

  // const wallet = useWallet();
  // const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  // const onMint = async () => {
  //   try {
  //     setIsMinting(true);
  //     if (wallet.connected && candyMachine?.program && wallet.publicKey) {
  //       const mintTxId = await mintOneToken(
  //         candyMachine,
  //         props.config,
  //         wallet.publicKey,
  //         props.treasury
  //       );

  //       const status = await awaitTransactionSignatureConfirmation(
  //         mintTxId,
  //         props.txTimeout,
  //         props.connection,
  //         'singleGossip',
  //         false
  //       );

  //       if (!status?.err) {
  //         setAlertState({
  //           open: true,
  //           message: 'Congratulations! Mint succeeded!',
  //           severity: 'success',
  //         });
  //       } else {
  //         setAlertState({
  //           open: true,
  //           message: 'Mint failed! Please try again!',
  //           severity: 'error',
  //         });
  //       }
  //     }
  //   } catch (error: any) {
  //     // TODO: blech:
  //     let message = error.msg || 'Minting failed! Please try again!';
  //     if (!error.msg) {
  //       if (error.message.indexOf('0x138')) {
  //       } else if (error.message.indexOf('0x137')) {
  //         message = `SOLD OUT!`;
  //       } else if (error.message.indexOf('0x135')) {
  //         message = `Insufficient funds to mint. Please fund your wallet.`;
  //       }
  //     } else {
  //       if (error.code === 311) {
  //         message = `SOLD OUT!`;
  //         setIsSoldOut(true);
  //       } else if (error.code === 312) {
  //         message = `Minting period hasn't started yet.`;
  //       }
  //     }

  //     setAlertState({
  //       open: true,
  //       message,
  //       severity: 'error',
  //     });
  //   } finally {
  //     if (wallet?.publicKey) {
  //       const balance = await props.connection.getBalance(wallet?.publicKey);
  //       setBalance(balance / LAMPORTS_PER_SOL);
  //     }
  //     setIsMinting(false);
  //   }
  // };

  // useEffect(() => {
  //   (async () => {
  //     if (wallet?.publicKey) {
  //       // const balance = await props.connection.getBalance(wallet.publicKey);
  //       // setBalance(balance / LAMPORTS_PER_SOL);
  //     }
  //   })();
  // }, [wallet, props.connection]);

  /**
   *  <MintButton
   *    disabled={isSoldOut || isMinting || !isActive}
   *    onClick={onMint}
   *    variant="contained"
   *  >
   *    {isSoldOut ? (
   *      'SOLD OUT LOSER'
   *    ) : isActive ? (
   *      isMinting ? (
   *        <CircularProgress />
   *      ) : (
   *        'MINT FOR A FUN SURPRISE ;)'
   *      )
   *    ) : (
   *      <Countdown
   *        date={startDate}
   *        onMount={({ completed }) => completed && setIsActive(true)}
   *        onComplete={() => setIsActive(true)}
   *        renderer={renderCounter}
   *      />
   *    )}
   *  </MintButton>
   */

  return (
    <HomeContainer>
      <FixedButtonsWrapper>
        <MuteToggle
          style={{ color: isMuted ? 'lightgrey' : 'white' }}
          onClick={handleToggleMuted}
        >
          {isMuted ? <FiVolumeX size={30} /> : <FiVolume2 size={30} />}
        </MuteToggle>
        {/* 
        {!wallet.connected ? (
          <ConnectButton>Connect Wallet</ConnectButton>
        ) : (
          <WalletAddyDisplay>
            {shortenAddress(wallet.publicKey?.toBase58() || '')}
          </WalletAddyDisplay>
        )} */}
      </FixedButtonsWrapper>
      <ScreamVid
        src="/v0-scream-asset.mp4"
        width="1280"
        height="1280"
        muted={isMuted}
        controls={false}
        autoPlay
        loop
        playsInline
        onEnded={(e) => {
          const vid = e.target as HTMLVideoElement;
          vid.pause();
          setTimeout(() => {
            vid.play();
          }, 4100);
        }}
      />
      <TextContainer>
        <ScreamsText>Screams</ScreamsText>
        <ScreamsSubText>Mint Coming Soon</ScreamsSubText>
      </TextContainer>
      {/* <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar> */}
    </HomeContainer>
  );
};

// interface AlertState {
//   open: boolean;
//   message: string;
//   severity: 'success' | 'info' | 'warning' | 'error' | undefined;
// }

// const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
//   return (
//     <CounterText>
//       {hours} hours, {minutes} minutes, {seconds} seconds
//     </CounterText>
//   );
// };

export default Home;
